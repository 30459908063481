import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'

declare global {
    interface Window {
        Alpine: typeof Alpine;
        grecaptcha: typeof grecaptcha;
    }
}

window.Alpine = Alpine

Alpine.plugin(focus)

/**
 * Navigation component
 */
Alpine.data('navigate', () => ({

    open: false,

    init() {
        const body = document.body

        const sidebar = document.getElementsByClassName('menu-sidebar')[0]

        /**
         * Handle transitions
         * AlpineJS classes are added or removed immediately so before these are fired -
         * this makes sense as the classes are triggering the transitions
         */
        sidebar?.addEventListener('transitionstart', () => {
            if (sidebar.classList.contains('open')) {
                body?.classList.add('no-scroll')
            } else {
                body?.classList.add('fade-out')
            }
        })

        sidebar?.addEventListener('transitionend', () => {
            if (!sidebar.classList.contains('open')) {
                body?.classList.remove('no-scroll', 'fade-out')
            }
        })
    }
}))

/**
 * Form component
 */

type ContactFields = 'first_name' | 'last_name' | 'email' | 'message'
Alpine.data('contact', () => ({

    form: <HTMLFormElement | null>null,

    inputs: null as NodeListOf<HTMLInputElement|HTMLTextAreaElement> | null,

    button: <HTMLButtonElement | null>null,

    init() {

        this.form = this.$el.querySelector('form') as HTMLFormElement

        this.inputs = this.form?.querySelectorAll('input:not([type="hidden"]),textarea:not([type="hidden"])')

        this.button = this.form?.querySelector('button[type="submit"]')

        // Add event listeners to the element
        this.inputs?.forEach( (field) => {
            field.addEventListener('blur', () => {
                this.validateField(field)
            })
        })
    },

    validateField(field: HTMLInputElement|HTMLTextAreaElement) {
        const aria = field.getAttribute('aria-errormessage')

        if (!field.checkValidity() && aria) {
            field.classList.add('error')
            const message = this.form?.querySelector('#' + aria)
            if (message) {
                message.textContent = field.validationMessage
            }
        } else if (field.checkValidity() && aria) {
            field.classList.remove('error')
            const message = this.form?.querySelector('#' + aria)
            if (message) {
                message.textContent = ''
            }
        }
    },

    handleSubmit(ev: Event) {

        ev.preventDefault()
        ev.stopPropagation()

        if (this.button) this.button.disabled = true;

        // We only stop normal submission if the form is not valid
        if (!this.form?.checkValidity()) {

            this.inputs?.forEach( (field) => {
                this.validateField(field)
            })

            // Scroll the first invalid element into view
            let focused = false

            this.inputs?.forEach( (field) => {
                if (field.checkValidity() && !focused) {
                    field.parentElement?.scrollIntoView()
                    focused = true
                }
            })

            if (this.button) this.button.disabled = false;
        } else {
            this.form?.requestSubmit()
        }
    }
}))

/**
 * Consents component
 */
Alpine.data('consents', (props: {}) => ({
    inputs: [] as HTMLInputElement[],

    init() {
        this.$el.querySelectorAll('input').forEach((input: HTMLInputElement) => {
            this.inputs.push(input)
        })

        this.$el.classList.add('show')
    },
    handleCustomize() {
        console.log('customize')
    },
    handleReject() {
        this.inputs.forEach((input: HTMLInputElement) => {
            input.value = 'n'
        })
    },
    handleAccept() {
        this.inputs.forEach((input: HTMLInputElement) => {
            input.value = 'y'
        })
    },
}))

Alpine.start()